<template>
  <v-row
    justify="start"
    align="stretch"
    class="overflow-hidden"
  >
    <v-col :cols="12">
      <h2>Добавить тег во все ЦОКи</h2>
    </v-col>
    <v-col :cols="2">
      <v-text-field
        v-model="form.group"
        dense
        label="Группа тега"
        persistent-placeholder
        persistent-hint
        hint="Только латиница и цифры"
      ></v-text-field>
    </v-col>
    <v-col :cols="3">
      <v-text-field
        v-model="form.tag"
        dense
        label="Идентификатор тега"
        persistent-placeholder
        persistent-hint
        hint="Только латиница и символ -"
      ></v-text-field>
    </v-col>
    <v-col :cols="1">
      <v-text-field
        v-model="form.limit"
        dense
        label="Лимит обрабатываемых записей"
        persistent-placeholder
        persistent-hint
        hint="Только числа"
      ></v-text-field>
    </v-col>
    <v-col :cols="1">
      <v-text-field
        v-model="form.offset"
        dense
        label="Отступ обрабатываемых записей"
        persistent-placeholder
        persistent-hint
        hint="Только числа"
      ></v-text-field>
    </v-col>
    <v-col :cols="2">
      <v-checkbox
        v-model="form.isMultiple"
        label="Множественное поле"
        color="indigo"
        hide-details
      ></v-checkbox>
    </v-col>
    <v-col :cols="3">
      <v-btn
        :disabled="loading || !form.tag || !form.group"
        @click="formHandler"
      >
        Запуск
      </v-btn>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "AddTagForm",
  props: {
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      form: {
        group: '',
        tag: '',
        limit: 0,
        offset: 0,
        isMultiple: false
      }
    };
  },
  methods: {
    async formHandler () {
      this.$emit('loadStateUpdated', true);

      const query = { ...this.form }
      if (!this.form.isMultiple) { delete query.multiple; }

      const { success, data, error } = await this.$store.dispatch('commands/addTagToDec', query);
      alert(success ? 'Успех! Кол-во обработаных записей - ' + data.rows_updated : 'Ошибка: ' + error);

      this.$emit('loadStateUpdated', false);
    }
  }
};
</script>
<template>
  <v-row
    justify="start"
    align="stretch"
    class="overflow-hidden"
  >
    <v-col :cols="12">
      <h2>Скопировать теги из групппы А в группу Б во всех ЦОКах</h2>
    </v-col>
    <v-col :cols="2">
      <v-text-field
        v-model="form.from"
        dense
        label="Группа А"
        persistent-placeholder
        persistent-hint
        hint="Только латиница и цифры"
      ></v-text-field>
    </v-col>
    <v-col :cols="3">
      <v-text-field
        v-model="form.to"
        dense
        label="Группа Б"
        persistent-placeholder
        persistent-hint
        hint="Только латиница и цифры"
      ></v-text-field>
    </v-col>
    <v-col :cols="1">
      <v-text-field
        v-model="form.limit"
        dense
        label="Лимит обрабатываемых записей"
        persistent-placeholder
        persistent-hint
        hint="Только числа"
      ></v-text-field>
    </v-col>
    <v-col :cols="1">
      <v-text-field
        v-model="form.offset"
        dense
        label="Отступ обрабатываемых записей"
        persistent-placeholder
        persistent-hint
        hint="Только числа"
      ></v-text-field>
    </v-col>
    <v-col :cols="3">
      <v-btn
        :disabled="loading || !form.from || !form.to"
        @click="formHandler"
      >
        Запуск
      </v-btn>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "CopyTagGroupsForm",
  props: {
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      form: {
        from: '',
        to: '',
        limit: 0,
        offset: 0
      }
    };
  },
  methods: {
    async formHandler () {
      this.$emit('loadStateUpdated', true);

      const query = { ...this.form }

      const { success, data, error } = await this.$store.dispatch('commands/copyDecTagGroups', query);
      alert(success ? 'Успех! Кол-во обработаных записей - ' + data.rows_updated : 'Ошибка: ' + error);

      this.$emit('loadStateUpdated', false);
    }
  }
};
</script>
<template>
    <div class="fill-height">
        <subpage-top-bar>Специальные команды</subpage-top-bar>
       
        <v-container v-if="allowEdit" fluid class="pt-0 pb-0">

            <!-- Loading -->
            <div v-if="loading" class="loading w-100 h-100 d-flex flex-row flex-nowrap justify-center align-center">
                <v-progress-circular
                    :size="150"
                    color="purple"
                    indeterminate
                ></v-progress-circular>
            </div>
            <!-- END Loading -->
            <v-row
                justify="start"
                align="stretch"
                class="overflow-hidden mt-4"
            >
                <v-col :cols="12">
                    <v-alert type="warning">Осторожно! Процессы запускаемые в этом разделе необратимо перезаписывают данные в БД. Перед любыми изменениями рекомендуется сделать дамп базы.</v-alert>
                </v-col>
            </v-row>

            <component
                v-for="component in formComponents"
                :key="component"
                :is="component"
                :loading="loading"
                @loadStateUpdated="onLoadStateUpdated"
            />
        </v-container>

        <v-container v-else class="pt-0 pb-0 text-center fill-height">
            <v-row>
                <v-col cols="4" offset="4">
                    <v-alert type="error">У вас недостаточно прав для доступа к этому разделу.</v-alert>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddTagForm from '@/components/commandForms/AddTagForm.vue'
import CopyTagGroupsForm from '@/components/commandForms/CopyTagGroupsForm.vue'
import ReplaceSubstringFromFieldForm from '@/components/commandForms/ReplaceSubstringFromFieldForm.vue'
import SubpageTopBar from '@/components/template/SubpageTopBar'

export default {
    name: "Commands",
    components: { AddTagForm, CopyTagGroupsForm, ReplaceSubstringFromFieldForm, SubpageTopBar },
    data () {
        return {
            loading: false,
            formComponents: [
                'add-tag-form',
                'copy-tag-groups-form',
                'replace-substring-from-field-form'
            ]
        };
    },
    computed: {
        ...mapGetters("user", ["allowEdit"])
    },
    methods: {
        onLoadStateUpdated (newState) {
            this.loading = newState;
        }
    }
};
</script>

<style lang="scss" scoped>
.loading {
    position: fixed;
    z-index: 9999;
    background-color: #ffffff59;
}
</style>
<template>
  <v-row
    justify="start"
    align="stretch"
    class="overflow-hidden"
  >
    <v-col :cols="12">
      <h2>Заменить подстроку в указаном поле во всех ЦОКах</h2>
    </v-col>
    <v-col :cols="3">
      <v-select
        v-model="form.fieldName"
        :items="fieldNames"
        label="Поле"
        persistent-placeholder
        persistent-hint
        hint="Выберите поле для обработки"
      ></v-select>
    </v-col>
    <v-col :cols="2">
      <v-text-field
        v-model="form.search"
        dense
        label="Искать"
        persistent-placeholder
        persistent-hint
        hint="Искомая подстрока"
      ></v-text-field>
    </v-col>
    <v-col :cols="2">
      <v-text-field
        v-model="form.replace"
        dense
        label="Заменить на"
        persistent-placeholder
        persistent-hint
        hint="Новая подстрока"
      ></v-text-field>
    </v-col>
    <v-col :cols="1">
      <v-text-field
        v-model="form.limit"
        dense
        label="Лимит обрабатываемых записей"
        persistent-placeholder
        persistent-hint
        hint="Только числа"
      ></v-text-field>
    </v-col>
    <v-col :cols="1">
      <v-text-field
        v-model="form.offset"
        dense
        label="Отступ обрабатываемых записей"
        persistent-placeholder
        persistent-hint
        hint="Только числа"
      ></v-text-field>
    </v-col>
    <v-col :cols="3">
      <v-btn
        :disabled="loading || !form.fieldName || !form.search || !form.replace"
        @click="formHandler"
      >
        Запуск
      </v-btn>
    </v-col>
  </v-row>
</template>


<script>
export default {
  name: "ReplaceSubstringFromFieldForm",
  props: {
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      fieldNames: [
        {text: 'Название', value: 'courseName'},
        {text: 'Описание', value: 'courseDescription'}
      ],
      form: {
        fieldName: '',
        search: '',
        replace: '',
        limit: 0,
        offset: 0
      }
    };
  },
  methods: {
    async formHandler () {
      this.$emit('loadStateUpdated', true);

      const query = { ...this.form }

      const { success, data, error } = await this.$store.dispatch('commands/replaceSubstringFromField', query);
      alert(success ? 'Успех! Кол-во обработаных записей - ' + data.rows_updated : 'Ошибка: ' + error);

      this.$emit('loadStateUpdated', false);
    }
  }
};
</script>